.search-input {
  position: relative;
  height: 48px;
  width: 400px;
  background: #ffffff;
  border-radius: 8px;
  .clearButton {
    svg {
      position: absolute;
      right: 20px !important;
      left: auto;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }
  input {
    height: 100%;
    width: 100%;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.16%;
    color: #676869;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    padding: 10px 20px 10px 50px;
  }
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
  }
}
