.newsCard {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 0px 20px rgba(111, 111, 111, 0.15);
  border-radius: 24px;
  margin-bottom: 24px;

  &One {
    display: flex;
    padding: 20px;
    border-bottom: 1px solid #4c535936;
    cursor: pointer;

    &Left {
      img {
        border-radius: 16px;
        width: 200px;
        height: 190px;
        object-fit: cover;
      }
    }
    &Right {
      width: 788px;
      margin-left: 24px;
      position: relative;
      &Div {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        max-height: 120px;
        h4 {
          color: #313538;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 16px;
        }
        p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          margin-bottom: 0px;

          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #464a4d;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
          }
        }
      }
      h4 {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #313538;
        margin-bottom: 12px;
      }
      p,
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.0016em;
        color: #464a4d;
        margin-bottom: 0px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
      }
      &Bottom {
        position: absolute;
        bottom: 0px;
        svg {
          path {
            stroke: #828282;
          }
        }
        span {
          font-weight: 400;
          font-size: 14px;
          color: #828282;
        }
        &Icon {
          white-space: nowrap;
          svg {
            path {
              stroke: #828282;
            }
          }
          span {
            font-weight: 400;
            font-size: 14px;
            color: #828282;
          }
        }
      }
    }
  }
  &Two {
    display: flex;
    padding: 20px;
    &Icon {
      margin-right: 36px;
      cursor: pointer;
      span {
        margin-left: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.0016em;
        color: #464a4d;
      }
    }
  }
  &Comment {
    border-top: 1px solid #e6e6e6;
    padding: 34px 24px 24px 24px;
    &Input {
      &Left {
        flex: 0 0 auto;
        img {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      &Right {
        flex: 1 1 auto;
        margin-left: 20px;
        width: 100px;
        position: relative;

        .sendButton {
          background-color: transparent;
          border: none;
          position: absolute;
          right: 50px;
          top: 14px;
          svg {
            path {
              stroke: green;
            }
          }
        }
        svg {
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
        input {
          border: 1px solid #e6e6e6;
          border-radius: 24px;
          padding: 14px 24px;
          height: 52px !important;
          background: transparent;
          cursor: pointer;
          color: #6b6e71;
          font-style: normal;
          outline: none;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.0016em;
        }
        textarea {
          min-height: 52px !important;
          border: 1px solid #e6e6e6;
          border-radius: 24px;
          padding: 14px 70px 14px 24px;
          background: transparent;
          cursor: pointer;
          color: #6b6e71;
          font-style: normal;
          outline: none;
          font-weight: 400;
          font-size: 16px !important;
        }

        .styledDiv {
          width: 40px;
          border-radius: 12px;
          position: absolute;
          top: 2px;
          right: 4px;
          &:hover {
            .BtnSvg {
              display: block !important;
            }
          }
          .BtnSvg {
            display: none !important;
          }
          .fileName {
            display: none !important;
          }
          label {
            border: none;
            display: block !important;
            padding: 0px !important;
            border-radius: 12px;
            min-width: auto !important;
            min-height: auto !important;
          }
          .Btns {
            position: absolute;
            left: 0px;
            top: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            pointer-events: none;
            font-family: "Roboto";
            font-size: 14px !important;

            &Div {
              position: relative;
              &:hover {
                .title-div {
                  display: block;
                }
              }
            }
            svg {
              display: block !important;
              pointer-events: none;
            }
            p {
              color: var(--dark-text-color);
            }
            button {
              width: max-content;
              padding: 8px 24px;
              border: none;
              border-radius: 12px;
              color: #121315;
              font-weight: 500;
            }
          }
          svg {
            display: none;
          }
          span {
            display: none;
          }
          .fileContent {
            display: flex;
            padding: 12px;
            align-items: center;
            justify-content: center;
          }
        }
        .image-preview {
          margin-top: 16px;
          img {
            width: 500px;
            height: 270px;
            object-fit: cover;
            border: 1px solid #b3b3b3;
            border-radius: 24px;
          }
        }
      }
    }
  }
}
.modal-footer {
  text-align: center;
}

.sendList {
  .search-input {
    width: 100%;
    margin-bottom: 16px;
  }
  &Body {
    overflow-y: scroll;
    max-height: 500px;
    &::-webkit-scrollbar {
      width: 4px;
      position: absolute;
      top: 16px;
      background-color: #d8d8d8;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #b3b3b3;
    }
    &Item {
      padding: 24px 12px;
      display: flex;
      border-bottom: 1px solid #edeeee;
      align-items: center;
      justify-content: space-between;
      &:last-child {
        border-bottom: none;
      }
      &Left {
        display: flex;
        .Left {
          img {
            width: 56px;
            height: 56px;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .Right {
          margin-left: 24px;
          h4 {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #313538;
            margin-bottom: 8px;
          }
          p {
            color: #989a9c;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .newsCard {
    &One {
      display: block;
      &Left {
        img {
          width: 100%;
        }
      }
      &Right {
        margin-top: 20px;
        margin-left: 0px;
        width: 100%;
        &Bottom {
          position: relative;
          margin-top: 20px;
        }
      }
    }
    &Two {
      padding: 10px;
      &Icon {
        margin-right: 30px;
      }
    }
    &Comment {
      padding: 20px;
    }
  }
  .modal {
    padding-left: 0px !important;
  }
  .modal-body {
    padding: 10px !important;
  }
}

.mentions__mention {
  position: relative;
  z-index: 1 !important;
  color: blue !important;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white !important;
  text-decoration: underline !important;
  pointer-events: none !important;
}
