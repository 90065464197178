.cardMain {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 0px 20px rgba(111, 111, 111, 0.15);
  border-radius: 24px;
  &Top {
    overflow: hidden;
    padding: 24px;
    &Left {
      img {
        width: 56px;
        height: 56px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    &Right {
      margin-left: 20px;
      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.0016em;
        color: #313538;
        margin-bottom: 4px;
        display: inline;
      }
      .time {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.0016em;
        color: #6f7274;
        margin-left: 16px;
        &::before {
          content: "";
          width: 4px;
          display: inline-block;
          height: 4px;
          border-radius: 7.5px;
          background-color: #a5a9ac;
          position: relative;
          bottom: 2px;
          right: 8px;
        }
      }
      .duty {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.0016em;
        color: #4c5359;
        margin-bottom: 0px;
      }
    }
  }
  &Center {
    overflow: hidden;
    padding: 0 24px;
    margin-bottom: 32px;

    &Text {
      .more {
        margin-top: 12px;
        color: #057953;
        background: transparent;
        border: none;
      }
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #464a4d;
        margin-bottom: 0px;
      }
      &Tag {
        display: inline-block;
        margin: 10px;
        margin-left: 0px;
        display: inline-block;
        white-space: nowrap;
        span {
          padding: 10px 15px;
          border: 1px solid #e6e6e6;
          border-radius: 8px;
          font-weight: 500;
          font-size: 12px;
          color: #7e8082;
        }
      }
    }
    &Img {
      height: 300px;
      width: 300px;
      margin-top: 24px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 24px;
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 24px;
      }
    }
  }
  &Bottom {
    display: flex;
    flex-wrap: wrap;
    padding: 24px;
    border-top: 1px solid #e6e6e6;
    &Icon {
      cursor: pointer;
      margin-right: 36px;
      span {
        margin-left: 5px;
      }
    }
  }

  &Comment {
    border-top: 1px solid #e6e6e6;
    padding: 34px 24px 24px 24px;
    &Input {
      &Left {
        flex: 0 0 auto;

        img {
          width: 56px;
          height: 56px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      &Right {
        flex: 1 1 auto;
        margin-left: 20px;
        width: 100px;
        position: relative;

        .sendButton {
          background-color: transparent;
          border: none;
          position: absolute;
          right: 50px;
          top: 14px;
          svg {
            path {
              stroke: green;
            }
          }
        }
        svg {
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
        input {
          border: 1px solid #e6e6e6;
          border-radius: 24px;
          padding: 14px 24px;
          height: 52px !important;
          background: transparent;
          cursor: pointer;
          color: #6b6e71;
          font-style: normal;
          outline: none;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.0016em;
        }
        textarea {
          min-height: 52px !important;
          border: 1px solid #e6e6e6;
          border-radius: 24px;
          padding: 14px 70px 14px 24px;
          background: transparent;
          cursor: pointer;
          color: #6b6e71;
          font-style: normal;
          outline: none;
          font-weight: 400;
          font-size: 16px !important;
        }

        .styledDiv {
          width: 40px;
          border-radius: 12px;
          position: absolute;
          top: 2px;
          right: 4px;
          &:hover {
            .BtnSvg {
              display: block !important;
            }
          }
          .BtnSvg {
            display: none !important;
          }
          .fileName {
            display: none !important;
          }
          label {
            border: none;
            display: block !important;
            padding: 0px !important;
            border-radius: 12px;
            min-width: auto !important;
            min-height: auto !important;
          }
          .Btns {
            position: absolute;
            left: 0px;
            top: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            pointer-events: none;
            font-family: "Roboto";
            font-size: 14px !important;

            &Div {
              position: relative;
              &:hover {
                .title-div {
                  display: block;
                }
              }
            }
            svg {
              display: block !important;
              pointer-events: none;
            }
            p {
              color: var(--dark-text-color);
            }
            button {
              width: max-content;
              padding: 8px 24px;
              border: none;
              border-radius: 12px;
              color: #121315;
              font-weight: 500;
            }
          }
          svg {
            display: none;
          }
          span {
            display: none;
          }
          .fileContent {
            display: flex;
            padding: 12px;
            align-items: center;
            justify-content: center;
          }
        }
        .image-preview {
          margin-top: 16px;
          img {
            width: 500px;
            height: 270px;
            object-fit: cover;
            border: 1px solid #b3b3b3;
            border-radius: 24px;
          }
        }
      }
    }
  }
  .modal-footer {
    text-align: center;
  }

  .sendList {
    padding: 8px;
    .search-input {
      width: 100%;
      margin-bottom: 16px;
    }
    &Body {
      overflow-y: scroll;
      max-height: 500px;
      &::-webkit-scrollbar {
        width: 4px;
        position: absolute;
        top: 16px;
        background-color: #d8d8d8;
        border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #b3b3b3;
      }
      &Item {
        padding: 24px 12px;
        display: flex;
        border-bottom: 1px solid #edeeee;
        align-items: center;
        justify-content: space-between;

        &Left {
          display: flex;
        }
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .cardMain {
    border-radius: 0px !important;
    &Bottom {
      .cardBottom {
        justify-content: space-between;
      }
    }
    &Center {
      overflow: hidden;
    }
    &Top {
      overflow: hidden;
    }
  }
  .modal {
    padding-left: 0px !important;
  }
  .modal-body {
    padding: 10px !important;
  }
}
@media only screen and (max-width: 1250px) {
  .cardMain {
    &Bottom {
      &Icon {
        svg {
          width: 20px;
          height: 20px;
        }
        &:nth-child(1) {
          span {
            &:nth-child(2) {
              display: none;
            }
          }
        }
        &:nth-child(3) {
          span {
            &:nth-child(2) {
              display: none;
            }
          }
        }
        &:nth-child(2) {
          span {
            display: none;
          }
        }
        &:nth-child(4) {
          span {
            display: none;
          }
        }
        &:nth-child(5) {
          span {
            display: none;
          }
        }
      }
    }
  }
}
