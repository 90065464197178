.emptyMain {
  text-align: center;
  margin-top: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #313538;
    margin-bottom: 0px;
  }
  img {
    height: 350px;
    width: 500px;
    object-fit: cover;
  }
}
