.tags {
    margin-right: 24px;
    margin-top: 10px;
    p {
        padding: 14px 32px;
        border: 1px solid #e6e6e6;
        border-radius: 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #7e8082;
        text-decoration: none;
        margin-bottom: 0px;
    }
}
