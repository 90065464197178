.moreMain {
  position: relative;
  &Icon {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    padding: 8px 13px;
    cursor: pointer;
  }
  &Bottom {
    position: absolute;
    right: 0px;
    z-index: 99;
    // margin-top: 13px;
    background: #ffffff;
    border: 1px solid #e6e6e6 !important;
    box-shadow: 0px 0px 20px rgba(111, 111, 111, 0.15) !important;
    border-radius: 16px;
    overflow: hidden;

    &Button {
      position: relative;
      z-index: 999;
      transition: 0.3s ease;
      border-top: 1px solid #e6e6e6;
      border: none;
      background: #ffffff;
      color: #313538 !important;
      width: 100%;
      text-align: start;
      font-weight: 400;
      transition: 0.3s ease;
      cursor: pointer;
      font-size: 14px;
      line-height: 21px;
      display: block;
      padding: 12px 55px 12px 12px;
      &:hover {
        background: #e6e6e6 !important;
        button {
          background: #e6e6e6 !important;
        }
      }
      &:first-child {
        border-bottom: 1px solid #e6e6e6;
      }
    }
  }
  .triangle {
    display: inline-block;
    margin: 0 5px;
    vertical-align: middle;
  }
  .triangle-4 {
    width: 18px;
    height: 18px;
    border-bottom: solid 15px#E6E6E6;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
}
