.teamCardMain {
  height: 420px;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 24px;

  img {
    border-radius: 24px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &Text {
    padding: 24px;
    position: absolute;
    bottom: 0px;
    cursor: pointer;
    border-radius: 0px 0px 24px 24px !important;
    backdrop-filter: blur(35px);
    color: white;
    transition: 0.3s !important;
    .innerTextDiv {
      display: none;
    }
    &:hover {
      height: 100%;
      background: #ffffff6d;
      color: black;
      border-radius: 24px;
      path {
        stroke: black;
      }
      p {
        display: block;
      }
      .innerTextDiv {
        display: block;
      }
    }
    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;
      path {
        stroke: white;
      }
    }
    h4 {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 0px;
    }
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: inline-block;
      padding: 24px 0px 16px 0;
    }
    p {
      overflow: hidden;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 0px;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
