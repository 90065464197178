.addMoreMain {
  &Options {
    position: absolute;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    top: 52px;
    z-index: 99;
    width: 173px;
    left: 18px;
    background: white;
    &Item {
      cursor: pointer;
      padding: 8px 13px;
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 400;
        margin-left: 14px;
      }
      svg {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
      }
      &:hover {
        background-color: #f2f8f6;
      }
    }
  }
}
