.sub_grid_wrapper {
  width: 100%;
  height: 100%;

  video {
    aspect-ratio: 16 / 9;
    width: 100%;
    object-fit: contain !important;
  }
  video.cover {
    object-fit: cover !important;
  }
}
.grid_wrapper {
  video {
    object-fit: contain !important;
    aspect-ratio: 16 / 9;
    width: 100%;
  }
  video.cover {
    object-fit: cover !important;
  }
}
.sub_grid_wrapper_1_img {
  grid-template-columns: repeat(1, 1fr) !important;
}
.sub_grid_wrapper_first_row {
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  height: 66.66%;
  gap: 0.25rem;
}
.sub_grid_wrapper_second_row {
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  height: 33.33%;
  gap: 0.25rem;
  margin-top: 0.25rem;
  grid-template-columns: repeat(2, 1fr);
}

.grid_wrapper {
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  height: 100%;
  gap: 0.25rem;
  img,
  video {
    min-height: 350px !important;
  }
}

.grid_wrapper_1_img {
  display: block !important;

  img {
    height: auto !important;
  }
  video {
    max-height: 500px !important;
    max-width: 80% !important;
    width: auto !important;
    height: auto !important;
    object-fit: contain !important;
  }
}

.grid_wrapper_2_img {
  grid-template-columns: repeat(2, 1fr);
}
.grid_wrapper_3_img {
  grid-template-columns: 1fr 2fr;
}

.grid_wrapper_5_img {
  grid-template-columns: repeat(2, 1fr 1fr);
}

.img {
  height: 400px;
  width: 100%;
  border-radius: 24px;
  object-fit: cover;
  overflow: hidden;
  cursor: pointer;
  object-position: top;
}

.show_more_img {
  height: 100%;
  width: 100%;
  overflow: hidden;
  opacity: 0.5;
  position: relative;
}

.show_more_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.img_1_1 {
  grid-column: 1;
  grid-row: 1;
  height: auto !important;
  width: 350px;
  max-height: 450px !important;
  margin: auto;
}

.img_2_1 {
  grid-column: 1/2;
  grid-row: 1;
}
.img_2_2 {
  grid-column: 2;
  grid-row: 1;
}

.img_3_1 {
  grid-column: 2;
  grid-row: 1 / 3;
}

.img_3_2 {
  grid-row: 1;
  grid-column: 1 / 2;
}
.img_3_3 {
  grid-row: 2;
  grid-column: 1 / 2;
}

.img_4_1 {
  grid-column: 1;
  grid-row: 1;
}

.img_4_2 {
  grid-column: 2;
  grid-row: 1;
}
.img_4_3 {
  grid-column: 1;
  grid-row: 2;
}
.img_4_4 {
  grid-column: 2;
  grid-row: 2;
}

.img_5_1 {
  grid-column: 1 / 3;
  grid-row: 1/3;
}

.img_5_2 {
  grid-row: 1;
  grid-column: 3/5;
}

.img_5_3 {
  grid-row: 3;
  grid-column: 1 / 2;
}
.img_5_4 {
  grid-row: 3;
  grid-column: 2/3;
}
.img_5_5 {
  grid-row: 3;
  grid-column: 3/5;
}

/* sub-grid-second-row */

.sub_grid_wrapper_3_img {
  grid-template-columns: repeat(3, 1fr);
}

.sub_img_3_1 {
  grid-column: 1 / 2;
  grid-row: 2;
}

.sub_img_3_2 {
  grid-column: 2 / 3;
  grid-row: 2;
}
.sub_img_3_3 {
  grid-column: 3;
  grid-row: 2;
}
@media only screen and (max-width: 992px) {
  .sub_grid_wrapper {
    img,
    video {
      height: 200px !important;
      border-radius: 0px !important;
    }
  }
  .grid_wrapper {
    img,
    video {
      border-radius: 0px !important;
    }
  }
  .img_1_1 {
    width: 100% !important;
    object-fit: cover !important;
  }
  .grid_wrapper_1_img video {
    width: 100% !important;
    height: auto !important;
  }
}
