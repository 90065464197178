input {
  color: #6b6e71;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 21px !important;
  letter-spacing: 0.0016em;
}
.fileSlider {
  .carousel-slider {
    border-radius: 24px !important;
    overflow: hidden;
  }
  .otherPhotoImgSide {
    height: 300px;
    video {
      width: 100%;
    }
  }
}
.cardMainCommentList {
  &Box {
    &Right {
      .noFile {
        .fileSlider {
          margin-left: 0px !important;
        }
      }
      &Inner {
        textarea {
          border: none !important;
          outline: none;
        }
        .photoDownladerFile {
          label {
            width: 50px !important;
            position: absolute !important;
            right: -7px !important;
            max-width: auto !important;
            min-width: auto !important;
            top: -54px !important;
            input {
              width: 30px !important;
            }
          }
        }
      }
      label {
        width: 50px !important;
        position: absolute !important;
        right: -7px !important;
        max-width: auto !important;
        min-width: auto !important;
        top: -54px !important;
        input {
          width: 30px !important;
        }
      }
      .fileSlider {
        margin: 0 20px 20px 20px;
      }
      .Btns {
        position: absolute !important;
        right: -7px !important;
        top: -47px !important;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
