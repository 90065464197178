.modal-body {
  overflow: hidden;
}
.sendList {
  &Body {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 500px;
    &::-webkit-scrollbar {
      width: 4px;
      position: absolute;
      top: 16px;
      background-color: #d8d8d8;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #b3b3b3;
    }
    &Item {
      padding: 12px;
      display: flex;
      border-bottom: 1px solid #edeeee;
      align-items: center;
      justify-content: space-between;

      &Left {
        display: flex;
      }
    }
  }
}
