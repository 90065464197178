.fileContent {
  width: 300px;
  display: flex;
  position: relative;
  top: 20px;

  span {
    width: 100% !important;
    height: 357px !important;
  }
  img {
    border-radius: 8px;
    object-fit: cover;
  }
  .deleteIcon {
    display: block;
    width: 25px;
    height: 25px;
    position: absolute;
    left: 10px;
    top: 5px;
    cursor: pointer;
    svg {
      path {
        stroke: green;
      }
    }
  }
}
.styleDiv {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 12px;
  position: absolute;
  top: -10px;
  right: 25px;
  z-index: 1;
  &:hover {
    .fileName {
      display: block !important;
      z-index: 999;
    }
  }

  svg {
    display: none;
  }
  .fileName {
    display: none !important;
  }

  label {
    border: none;
    align-items: center;
    text-align: center;
    height: 40px;
    width: 50px !important;
    max-width: 50px !important;
    min-width: 50px !important;
    position: absolute;
    input {
      width: 40px !important;
    }
  }
  .Btns {
    position: absolute;
    left: -5px;
    top: 15px !important;
    right: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    .gallery {
      img {
        margin-top: 40px;
      }
    }

    p {
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      color: var(--dark-text-color);
    }
    .fileType {
      margin-top: 20px;
    }
    button {
      width: max-content;
      padding: 8px 24px;
      border: none;
      border-radius: 12px;
      background-color: transparent;

      color: #121315;
      font-weight: 500;
      margin-top: 5px;
    }
    svg {
      display: block !important;
      pointer-events: none;
    }
  }

  span {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .fileContent {
    width: 100%;
  }
}
