.inputs {
  position: relative;

  .padding {
    padding-right: 56px;
  }
  label {
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--title-color);
  }
  .invalid {
    border: 1px solid #ff0000;
  }
  input {
    width: 100%;
    height: 52px;
    border-radius: 8px;
    background: #ffff;
    color: #464a4d;
    line-height: 24px;
    border: 1px solid #b3b3b3;
    padding: 16px;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    // outline: none;
    &:focus {
      outline: 2px solid transparent;
    }
  }
  svg {
    position: absolute;
    top: 14px;
    right: 18px;
    width: 24px;
    height: 24px;
    color: var(--dark-text-color);
  }
}
