.header-icon {
    margin-left: 10px;
    background: #f3f8f6;
    border-radius: 50px;
    padding: 10px 12px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    svg {
        height: 20px;
        width: 20px;
    }
    .dote {
        position: absolute;
        width: 10px;
        height: 10px;
        top: 2px;
        right: 4px;
    }
}

.header-titles {
    :nth-child(1) {
        margin-left: 0px;
    }
    a {
        text-decoration: none;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: #313538;
        margin-left: 40px;
        &:hover {
            color: #057953;
        }
    }
}

.main-header {
    padding: 16px 48px;
    z-index: 99;
    .search-div {
        position: relative;
        .sendList {
            position: absolute;
            z-index: 999;
            background: #ffffff;
            border: 1px solid #e6e6e6;
            border-radius: 8px;
            width: 100%;
            margin-top: 6px;
            &Body {
                &Item {
                    padding: 16px;
                }
            }
            &Button {
                border-top: 1px solid #edeeee;
                border-right: 0px;
                border-left: 0px;
                border-bottom: 0px;
                width: 100%;
                background: none;
                text-align: center;
                font-weight: 600;
                font-size: 14px;
                letter-spacing: 0.0016em;
                color: #313538;
                padding: 16px 0;
            }
        }
    }
}

.title-active {
    color: #057953 !important;
    font-weight: 600 !important;
}

.mobile-menu {
    padding: 24px;
    display: none;
}

.mobile-sidebar {
    overflow-y: scroll;
    left: 0;
    position: absolute;
    top: 0;
    height: 100vh !important;
    z-index: 5;
    background: #ffffff;
    padding: 24px;

    &_border {
        border-bottom: 1px solid #edeeee;
        &_left {
            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
        }
        &_right {
            margin-left: 14px;
            h4 {
                font-weight: 600;
                font-size: 16px;
                line-height: 21px;
                color: #313538;
                margin-bottom: 4px;
            }
            p {
                font-weight: 400;
                font-size: 12px;
                line-height: 21px;
                color: #313538;
            }
        }
    }
    &_body {
        border-bottom: 1px solid #edeeee;

        padding-left: 0px !important;
        position: relative;

        .search-input {
            margin: 14px 0;
            width: 100%;
        }
        &_item {
            list-style: none;
            margin-bottom: 8px;
            padding: 12px;
            a {
                text-decoration: none;
                display: flex;
                align-items: center;
            }
            span {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.0016em;
                color: #313538;
                margin-left: 20px;
            }
            svg {
                width: 20px;
                height: 20px;
            }
        }
        .sendList {
            position: absolute;
            z-index: 999;
            background: #ffffff;
            border: 1px solid #e6e6e6;
            border-radius: 8px;
            width: 100%;
            &Body {
                &Item {
                    padding: 16px;
                }
            }
            &Button {
                border-top: 1px solid #edeeee;
                border-right: 0px;
                border-left: 0px;
                border-bottom: 0px;
                width: 100%;
                background: none;
                text-align: center;
                font-weight: 600;
                font-size: 14px;
                letter-spacing: 0.0016em;
                color: #313538;
                padding: 16px 0;
            }
        }
    }
    &_footer {
        border-bottom: 1px solid #edeeee;
        padding-left: 0px;
    }
    &_close {
        font-size: 26px;
        transform: rotate(45deg);
        position: absolute;
        right: 20px;
        padding: 14px 0;
        background: none;
        border: none;
        top: 0px;
    }
    &_button {
        cursor: pointer;
        svg {
            margin-right: 14px;
            width: 22px;
            height: 22px;
        }
        button {
            color: #e40000;
            border: none;
            background: none;
        }
    }
}

@media only screen and (max-width: 1020px) {
    .main-header {
        display: none !important;
    }
    .mobile-menu {
        display: block !important;
    }
    .mobile-sidebar {
        width: 100%;
    }
}

@media only screen and (min-width: 1020px) and (max-width: 1190px) {
    .main-header {
        .search-input {
            width: 100%;
        }
    }
}
