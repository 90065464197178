.btnMain {
  padding: 10px 16px;
  border-radius: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.0016em;
  background-color: transparent;
  svg {
    margin-right: 12px;
  }
}

.glassy_background {
  color: #37b34a;
  border: 1px solid #37b34a;
}
.purple_background {
  color: #86499b;
  border: 1px solid #86499b;
}

.gray_background {
  color: #464a4d;
  border-radius: 24px !important;
  border: 1px solid #e6e6e6;
}
.light_background {
  color: #d22137;
  border: 1px solid #d22137;
}
