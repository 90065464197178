.pageTitleMain {
    h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
        letter-spacing: 0.0016em;
        color: #313538;
        margin-bottom: 0px;
    }
    &Inner {
        display: flex;
        margin-top: 24px;
        margin-bottom: 40px;
        align-items: center;
        &Button {
            border: none !important;
            background-color: transparent !important;
        }
        h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.0016em;
            color: #313538;
            margin-bottom: 0px;
            margin-left: 16px;
        }
        svg {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }
}
