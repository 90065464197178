.storyCard {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 0px 20px rgba(111, 111, 111, 0.15);
  border-radius: 24px;
  display: flex;
  height: 480px;

  &Left {
    position: relative;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 24px;
    }
    .blur {
      transition: all 500ms ease-in-out;
      border-radius: 24px;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      background: #4c5359af;
    }
  }
  &Right {
    padding: 24px;
    h3 {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #313538;
      margin-bottom: 0px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #464a4d;
      margin: 24px 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 12;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
    }
    button {
      background: transparent;
      color: #313538;
      font-weight: 500;
      font-size: 16px;
      border: none;
      text-decoration: underline;
      line-height: 24px;
    }
    .bottom {
      position: absolute;
      // text-align: inherit;
      overflow: hidden !important;
      bottom: 24px;
      h4 {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #313538;
        margin-bottom: 4px;
        max-width: 220px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #989a9c;
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .storyCard {
    &Right {
      padding: 20px;
      p {
        margin: 5px 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
      }
    }
  }
}
