.footerMain {
  position: relative !important;
  z-index: 999;
  display: flex;
  bottom: 0px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 33px 100px;
  border-top: 1px solid #aab6bf;
  background: #ffffffb8;
  span {
    color: var(--text-dark, #313538);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  a {
    text-decoration: none;
    color: #313538;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    &:hover {
      color: #313538;
    }
  }
  .link {
    color: #057953;
    text-decoration: underline;
  }
  .circle {
    position: relative;
    margin-left: 16px;
    &::before {
      content: "";
      width: 4px;
      display: inline-block;
      height: 4px;
      border-radius: 7.5px;
      background-color: #a5a9ac;
      position: relative;
      bottom: 2px;
      right: 8px;
    }
  }
}
.footer {
  height: 100%;
  width: 100%;
}
@media only screen and (max-width: 992px) {
  .footerMain {
    display: block;
    text-align: center;
    padding: 20px 80px;
    &Top {
      a {
        display: block;
        margin-bottom: 20px;
        span {
          &::before {
            display: none !important;
          }
        }
      }
    }
  }
}
