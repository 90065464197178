.postCard {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 0px 20px rgba(111, 111, 111, 0.15);
  border-radius: 24px;
  padding: 24px;
  &One {
    &Left {
      img {
        width: 56px;
        height: 56px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    &Right {
      width: 100%;
      margin-left: 20px;
      button {
        border: 1px solid #e6e6e6;
        border-radius: 24px;
        padding: 14px 24px;
        height: 52px;
        background: transparent;
        cursor: pointer;
        color: #989a9c;
      }
    }
  }
  &Two {
    display: flex;
    margin-top: 47px;
    &Left {
      .btnMain {
        margin-right: 16px;
      }
      input[type="radio"] {
        display: none;
      }

      input[type="radio"]:checked + .green-border {
        background: #37b34a;
        border: none;
        color: white;
      }
      input[type="radio"]:checked + .purple-border {
        background: #86499b;
        border: none;
        color: white;
      }

      label {
        margin-right: 16px;
        padding: 14px 16px;
        border-radius: 13px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        cursor: pointer;
        letter-spacing: 0.0016em;
      }
      .green-border {
        border: 1px solid #37b34a;
        color: #37b34a;
      }
      .purple-border {
        border: 1px solid #86499b;
        color: #86499b;
      }
    }

    &Right {
      svg {
        width: 20px;
        height: 20px;
        margin-left: 20px;
        cursor: pointer;
      }
      button {
        margin-left: 40px;
      }
      #profilePic {
        display: none;
      }
    }
  }
}
.body-div {
  &Body {
    position: relative;
    &Emoji {
      &Icon {
        z-index: 999;
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }
    }
  }
  input {
    width: 100%;
    margin-bottom: 0px !important;
  }
  &Top {
    &Img {
      img {
        width: 56px;
        height: 56px;
        border-radius: 50%;
      }
    }
    &Right {
      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.0016em;
        color: #313538;
        margin-bottom: 4px;
      }
      span {
        font-weight: 400 !important;
        font-size: 14px !important;
        color: #4c5359 !important;
        margin-bottom: 0px;
        margin-left: 0px !important;
      }
    }
  }
  &Upload {
    // margin-top: 24px;
    .files {
      button {
        background: none;
        border: none;
        margin-left: 10px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
.footer-div {
  padding: 24px;
  display: flex;
  justify-content: space-between;
}

.styledDiv {
  width: 40px;
  border-radius: 12px;
  position: relative;
  &:hover {
    .BtnSvg {
      display: block !important;
    }
  }
  .BtnSvg {
    display: none !important;
  }
  .fileName {
    display: none !important;
  }
  label {
    border: none;
    display: block !important;
    padding: 0px !important;
    border-radius: 12px;
    min-width: auto !important;
    min-height: auto !important;
  }
  .Btns {
    position: absolute;
    left: 0px;
    top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
    font-family: "Roboto";
    font-size: 14px !important;

    &Div {
      position: relative;
      &:hover {
        .title-div {
          display: block;
        }
      }

      .title-div {
        display: none;
        position: absolute;
        bottom: 20px;
        transition: 0.3s ease;
        margin-bottom: 15px;
        left: -9px;
        padding: 8px 16px;
        background: #ffffff;
        border: 1px solid #e6e6e6;
        box-shadow: 0px 0px 20px rgba(111, 111, 111, 0.15);
        border-radius: 12px;
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #313538;
          margin-bottom: 0px;
        }
      }
    }
    svg {
      display: block !important;
      pointer-events: none;
    }
    p {
      color: var(--dark-text-color);
    }
    button {
      width: max-content;
      padding: 8px 24px;
      border: none;
      border-radius: 12px;
      color: #121315;
      font-weight: 500;
    }
  }
  svg {
    display: none;
  }
  span {
    display: none;
  }
  .fileContent {
    display: flex;
    padding: 12px;
    align-items: center;
    justify-content: center;
  }
}
.active-green {
  background: #37b34a !important;
  color: white !important;
}

@media only screen and (max-width: 992px) {
  .postCard {
    border-radius: 0px;
    &Two {
      margin-top: 20px;
      display: block;
      &Right {
        margin-top: 20px;
        svg {
          &:first-child {
            margin-left: 0px;
          }
        }
        button {
          margin-left: 0px;
        }
      }
    }
  }
  .filepond--wrapper {
    width: 100% !important;
  }
  .modal-dialog {
    width: 100% !important;
    max-width: inherit !important;
    margin: 0px !important;
    .modal-content {
      border: none !important;
      border-radius: 0px !important;
      width: 100% !important;
      .modal-header {
        .btn-close {
          left: 16px !important;
          right: auto;
          top: 24px;
        }
        border-bottom: 1px solid #4c53594e;
      }
    }
    .modal-footer {
      .footer-div {
        display: block;
        .postCard {
          &Two {
            &Right {
              svg {
                margin-left: 0px;
              }
            }
          }
        }
      }
    }
    .modal-body {
      margin-top: 20px;
      .body-div {
        &Body {
          height: 100%;
        }
      }
    }
  }
}
