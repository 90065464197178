.innerHeaderUl {
  &Li {
    width: 100%;
    position: relative;
    text-align: center;

    a {
      width: 100%;
      float: left;
      text-align: center;
      // padding: 32px 20px;
      text-decoration: none;
      background: #c9e2da;
      text-decoration: none;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.0016em;
      color: #313538;
      height: 75px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      white-space: normal;
      word-wrap: break-word;
      overflow: hidden;

      &:hover {
        color: #313538;
      }
    }
  }
}

.active {
  z-index: 3;
}

.active a {
  background: #fafcfc;
  box-shadow: inset 0px 10px 9px -3px rgb(111 111 111 / 10%);

  color: #057953 !important;
  font-weight: 600 !important;
}

.innerHeaderUl li:before,
.innerHeaderUl li:after,
.innerHeaderUl li a:before,
.innerHeaderUl li a:after {
  position: absolute;
  bottom: 0;
}
.innerHeaderUl li:first-child::before {
  display: none;
}

.innerHeaderUl li {
  &:last-child {
    a {
      &::after {
        display: none;
      }
    }
    &::after {
      display: none;
    }
  }
}

.innerHeaderUl li:last-child:after,
.innerHeaderUl li:last-child a:after,
.innerHeaderUl li:first-child:before,
.innerHeaderUl li:first-child a:before,
.innerHeaderUl .active:after,
.innerHeaderUl .active:before,
.innerHeaderUl .active a:after,
.innerHeaderUl .active a:before {
  content: "";
}

.innerHeaderUl li:first-child a:before {
  display: none;
}

.innerHeaderUl .active:before,
.innerHeaderUl .active:after {
  background: #fafcfc;
  z-index: 1;
}
.innerHeaderUl li:before,
.innerHeaderUl li:after {
  background: #c9e2da;

  width: 10px;
  height: 10px;
}
.innerHeaderUl li:before {
  left: -10px;
}
.innerHeaderUl li:after {
  right: -10px;
}

.innerHeaderUl li a:after,
.innerHeaderUl li a:before {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  background: white;
  z-index: 2;
}
.innerHeaderUl .active a:after,
.innerHeaderUl .active a:before {
  background: #c9e2da;
}

.innerHeaderUl li:first-child.active a:before,
.innerHeaderUl li:last-child.active a:after {
  background: white;
}
.innerHeaderUl li a:before {
  left: -20px;
}
.innerHeaderUl li a:after {
  right: -20px;
}
@media only screen and (max-width: 992px) {
  .innerHeaderUl {
    overflow-x: scroll !important;
    justify-content: unset !important;
    padding: 10px 20px !important;
    white-space: nowrap;
    overflow: hidden;
    &Li {
      padding: 12px 24px;
      border-radius: 24px !important;
      margin-right: 12px;
      background: #fafafa;
      display: flex;
      align-items: center;
      a {
        font-size: 15px !important;
        line-height: 22px !important;
        padding: 0px !important;
        background: transparent !important;
        box-shadow: none !important;
        padding: 32px 20px;
        height: auto !important;

        &::before,
        &::after {
          display: none;
        }
      }
      &::before,
      &::after {
        display: none;
      }
    }
  }
  .ullist .active {
    background: #cde4dd;
    z-index: 1;
    position: relative;
    box-shadow: none !important;
  }
}
