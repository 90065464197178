.communityCard {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 0px 20px rgba(111, 111, 111, 0.15);
  border-radius: 24px;
  padding: 24px;
  &Top {
    display: flex;
    &Left {
      cursor: pointer;

      &One {
        img {
          width: 64px;
          height: 64px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      &Two {
        margin-left: 16px;
        h4 {
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: #313538;
          margin-bottom: 8px;
        }
        span {
          color: #6f7274;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
  &Bottom {
    cursor: pointer;
    margin-top: 24px;
    p {
      color: #464a4d;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
    }
  }
}
@media only screen and (max-width: 992px) {
  .communityCard {
    &Top {
      display: block;
      .eventCardRightBottomRight {
        margin-top: 20px;
      }
      &Right {
        margin-top: 20px;
      }
    }
  }
}
.joinButton {
  svg {
    path {
      stroke: white !important;
    }
  }
}
