.eventCard {
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(111, 111, 111, 0.15);
    border-radius: 24px;
    display: flex;
    padding: 20px 0px 20px 20px;
    &Left {
        position: relative;
        cursor: pointer;
        img {
            width: 255px;
            height: 216px;
            object-fit: cover;
            border-radius: 24px;
        }
        button {
            position: absolute;
            background: #86499b;
            border-radius: 8px;
            color: white;
            font-weight: 500;
            font-size: 14px;
            padding: 6px 16px;
            top: 6px;
            left: 6px;
            border: none;
        }
    }
    &Right {
        width: 100%;
        &Top {
            cursor: pointer;
            padding-left: 24px;
            margin-right: 24px;
            margin-bottom: 24px;
            span {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.0016em;
                color: #057953;
            }
            .before {
                margin-left: 10px;
                &::before {
                    background-color: #057953;
                    content: "";
                    width: 4px;
                    display: inline-block;
                    height: 4px;
                    border-radius: 7.5px;
                    position: relative;
                    bottom: 2px;
                    right: 8px;
                }
            }
            h4 {
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                color: #313538;
                margin-bottom: 20px;
            }
            .time {
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                letter-spacing: 0.0016em;
                color: #464a4d;
                opacity: 0.9;
                display: inherit;
            }
            .hours {
                margin-left: 16px;
                &::before {
                    background-color: #4c535967;
                    content: "";
                    width: 4px;
                    display: inline-block;
                    height: 4px;
                    border-radius: 7.5px;
                    position: relative;
                    top: 8px;
                    right: 8px;
                }
            }
        }
        &Bottom {
            border-top: 1px solid #4c535944;
            padding: 30px 24px 0px 24px;

            &Left {
                align-items: center;
                &Img {
                    display: flex;
                    align-items: center;
                    img {
                        &:nth-child(2) {
                            margin-left: -6px;
                        }
                        &:nth-child(3) {
                            margin-left: -6px;
                        }
                        &:nth-child(4) {
                            margin-left: -6px;
                        }
                        width: 20px;
                        height: 20px;
                        object-fit: cover;
                        border-radius: 24px;
                        border: 1px solid white;
                    }
                }
                span {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #313538;
                    margin-left: 16px;
                }
            }
        }
    }
}

.card-active {
    border: 1px solid #86499b;
}

@media only screen and (max-width: 992px) {
    .eventCard {
        padding: 0;
        display: block;
        &Left {
            padding: 20px;
            img {
                width: 100%;
            }
            button {
                top: 28px;
                left: 28px;
            }
        }
        &Right {
            width: 100%;
            &Top {
                .time {
                    display: block !important;
                }
                .hours {
                    &::before {
                        top: -3px !important;
                    }
                }
            }
            &Bottom {
                padding: 20px;
                &Left {
                    span {
                        margin-left: 32px;
                    }
                }
            }
        }
    }
}
