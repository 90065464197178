* {
  margin: 0px;
  padding: 0px;
}
// @font-face {
//   font-family: NotoColorEmojiLimited;
//   unicode-range: U+1F1E6-1F1FF;
//   src: url(https://raw.githack.com/googlefonts/noto-emoji/main/fonts/NotoColorEmoji.ttf);
// }
@font-face {
  font-family: "Noto Color Emoji";
  src: url("https://fonts.gstatic.com/s/notiemoji/v1/NotoEmoji-Regular.woff2")
    format("woff2");
  unicode-range: U+1F000-1FFFF, U+2600-26FF, U+2700-27BF, U+2300-23FF, U+2B50,
    U+1F004-1F0CF, U+1F1E6-1F1FF, U+1F3F4;
}
body {
  font-family: "Montserrat", serif !important;
  font-style: normal !important;
  letter-spacing: 0.0016em !important;
}
:root {
  color-scheme: only light;
}
.css-1gsv261 {
  border: none !important;
}
.css-w8dmq8 {
  font-family: "Montserrat", serif !important;
}
.css-1aquho2-MuiTabs-indicator {
  display: none;
}
.ql-align-right {
  text-align: right;
}
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
.contactMainTabs .Mui-selected {
  border: 1px solid #313538;
  border-radius: 10px;
  color: #313538 !important;
  opacity: 1 !important;
  padding: 8px 16px !important;
}
.searchMainTabs .Mui-selected {
  border: 1px solid #313538;
  border-radius: 10px;
  color: #313538 !important;
  opacity: 1 !important;
  padding: 8px 16px !important;
}
.settingsMainLeftTabs .Mui-selected {
  border: 1px solid #313538;
  border-radius: 10px;
  color: #313538 !important;
  opacity: 1 !important;
  padding: 8px 16px !important;
}
.starIcon {
  svg {
    fill: white;
    stroke: white;
  }
}
.newsMainTabs .Mui-selected {
  border: 1px solid #313538;
  border-radius: 10px;
  color: #313538 !important;
  opacity: 1 !important;
  padding: 8px 16px !important;
}
.eventsMainTabs .Mui-selected {
  border: 1px solid #313538;
  border-radius: 10px;
  color: #313538 !important;
  opacity: 1 !important;
  padding: 8px 16px !important;
}
.communityMain .Mui-selected {
  border: 1px solid #313538;
  border-radius: 10px;
  color: #313538 !important;
  opacity: 1 !important;
  padding: 8px 16px !important;
}

.libraryMain .Mui-selected {
  border: 1px solid #313538;
  border-radius: 10px;
  color: #313538 !important;
  opacity: 1 !important;
  padding: 8px 16px !important;
}

.notificationMain .Mui-selected {
  border: 1px solid #313538;
  border-radius: 10px;
  color: #313538 !important;
  opacity: 1 !important;
  padding: 8px 16px !important;
}
.Mui-selected {
  font-family: "Montserrat", serif !important;
}

.MuiTabPanel-root {
  padding: 0px !important;
}

.MuiTab-root {
  font-family: "Montserrat", serif !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  letter-spacing: 0.0016em !important;
  color: #6d7173 !important;
  text-transform: none !important;
  min-height: auto !important;
  padding: 0px !important;
  margin-right: 30px !important;
}
.MuiTabs-indicator {
  display: none !important;
}
.MuiPaginationItem-root {
  background: #f6f6f6 !important;
  margin: 0px !important;
  border: none !important;
  border-radius: 0px !important;
  color: #626668 !important;
  font-size: 12px !important;
}
.MuiPaginationItem-previousNext {
  margin: 0 8px !important;
  background: #313538 !important;
  color: white !important;
  border-radius: 8px !important;
}
.MuiPagination-ul {
  .Mui-selected {
    border-radius: 0px !important;
    font-weight: 700 !important;
    color: #313538 !important;
  }
}

.input-design .MuiInputBase-input {
  padding: 0px;
}
.MuiInputBase-root {
  font-family: "Montserrat", serif !important;
  width: 100%;
  &::before,
  &::after {
    content: "" !important;
    border-bottom: 0 !important;
  }
}
.MuiTabs-root {
  min-height: auto !important;
}

.MuiSnackbar-root {
  .MuiAlert-root {
    background: #c9e2db;
    box-shadow: 0px 0px 20px rgba(111, 111, 111, 0.15);
    border-radius: 16px;
    color: #464a4d;
    border-bottom: 2px solid #057953;
  }
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: green !important;
}

.btn-close:focus {
  outline: none !important;
  box-shadow: none !important;
}

.modal-dialog {
  width: 874px;
  .modal-header {
    border: none;
    display: block !important;
    position: relative !important;
    .modal-title {
      text-align: center;
    }
    .btn-close {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }
  .modal-content {
    border-radius: 24px;
  }
  .modal-body {
    padding-top: 0px;
    .body-div {
      &Top {
        &Img {
          img {
            width: 56px;
            height: 56px;
            object-fit: cover;
          }
        }
        span {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.0016em;
          color: #313538;
          margin-left: 12px;
        }
      }
      &Body {
        margin-top: 24px;
        input {
          border: none;
          outline: none;
        }
      }
    }
  }

  .modal-footer {
    border: none;
    box-shadow: none !important;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 100% !important;
  }
}
.font_14 {
  font-size: 14px !important;
}

.font_16 {
  font-size: 16px !important;
}

.pt_10_32 {
  padding: 10px 32px !important;
}
.pt_6_16 {
  padding: 6px 16px !important;
}
.pt_14_32 {
  padding: 14px 32px !important;
}
.pt_14_26 {
  padding: 14px 26px !important;
}
.pt_10_16 {
  padding: 10px 16px !important;
}
.pt_8 {
  padding: 8px !important;
}

.slick-slider {
  svg {
    width: 48px;
    height: 48px;
  }
}
.w-80 {
  width: 80%;
}

.MuiAccordionSummary-root {
  background: #ffffff !important;
  border: 1px solid #e6e6e6 !important;
  box-shadow: 0px 0px 20px rgba(111, 111, 111, 0.15);
  border-radius: 24px !important;
  padding: 12px 24px !important;
}
.Mui-expanded {
  border-radius: 24px 24px 0px 0px !important;
}
.MuiAccordion-root {
  box-shadow: none !important;
  background-color: transparent !important;
  &::before {
    display: none !important;
  }
  margin-bottom: 24px !important;
}
.MuiAccordionSummary-content {
  p {
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 30px !important;
  }
}
.MuiAccordionDetails-root {
  padding: 0px !important;
}
.MuiTypography-root {
  font-family: "Montserrat", serif !important;
}

.MuiAccordion-root:last-of-type {
  border-bottom-left-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
}
.MuiCollapse-wrapper {
  background: #ffffff !important;
  border-radius: 0 0 24px 24px !important;
}

.rdw-editor-wrapper {
  display: flex;
  flex-direction: column-reverse;
  color: #0e0e0e !important;
}
.rdw-editor-toolbar {
  border: none !important;
}
.rdw-option-wrapper {
  padding: 13px !important;
  height: 32px !important;
  margin: 0px !important;
  background-color: #dfdfdf !important;
  &:nth-child(3) {
    display: none !important;
  }
  &:nth-child(4) {
    display: none !important;
  }
}

.rdw-fontsize-dropdown {
  background-color: #dfdfdf !important;
  a {
    text-decoration: none;
    color: #0e0e0e !important;
  }
}
.rdw-editor-main {
  max-height: 260px;
  height: 250px !important;
  overflow-y: scroll;
  outline: none;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #b3b3b3;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  position: relative;
}
.public-DraftStyleDefault-block {
  margin: 0px !important;
}
.rdw-link-wrapper,
.rdw-colorpicker-wrapper,
.rdw-embedded-wrapper,
.rdw-emoji-wrapper,
.rdw-image-wrapper,
.rdw-block-wrapper,
.rdw-fontfamily-wrapper {
  display: none !important;
}
.rdw-inline-wrapper,
.rdw-fontsize-wrapper,
.rdw-list-wrapper,
.rdw-text-align-wrapper {
  margin-right: 12px;
}

.fv-plugins-message-container {
  margin-top: 0.25rem;

  .fv-help-block {
    font-family: "Montserrat", serif !important;
    color: #f64e60;
    font-size: 0.9rem;
    font-weight: 400;
  }
}
.react-datepicker__header {
  background-color: #057953;
}

.react-datepicker-year-header {
  color: white !important;
}
.react-datepicker__year-read-view--down-arrow {
  top: 3px;
}
.react-datepicker__navigation--years {
  position: fixed;
}
.react-datepicker__header__dropdown {
  color: white;
}
.react-datepicker__year-dropdown {
  color: black !important;
  max-height: 200px;
  overflow-y: scroll;
  background-color: white;
}
.MuiCheckbox-root {
  padding: 0px !important;
}
.css-1xc3v61-indicatorContainer {
  display: none !important;
}

#react-select-category-value-select-listbox {
  position: relative;
  z-index: 999;
}

body::-webkit-scrollbar {
  width: 7px !important;
  border-radius: 0px !important;
}

body::-webkit-scrollbar-thumb {
  background: #057953;
  border-radius: 0px !important;
}

.scrolled-body {
  overflow-y: auto;
  @media only screen and (max-width: 1440px) {
    max-height: 500px;
  }

  @media only screen and (min-width: 1440px) {
    max-height: 800px;
  }
}
.scrolled-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #057953 !important;
}

.scrolled-body::-webkit-scrollbar {
  width: 10px;
  background-color: #057953 !important;
}

.scrolled-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #057953 !important;
}
.ewqTBN input {
  width: 20px;
}
// .filepond--wrapper {
//   width: 574px;
// }
.filepond--credits {
  display: none;
}
.filepond--image-preview {
  background: white;
}
.carousel .thumb.selected {
  border: 1px solid #057953 !important;
}
.carousel .thumb {
  border: none !important;
  padding: 0px;
  border-radius: 8px;
}
.otherPhotoImgSide {
  border-radius: 24px;
  border: 1px solid #057953 !important;
}

.carousel .thumbs-wrapper {
  display: none;
}

@media only screen and (max-width: 992px) {
  .fileSlider {
    width: 100% !important;
  }
}
.fileSlider {
  margin: 20px 0;
}

.carousel .slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  // vertical-align: top;
  // border: 0;
  border-radius: 24px;
}

.quill {
  position: relative;
  margin-bottom: 40px;
}
.ql-toolbar {
  position: absolute;
  width: 100%;
  bottom: -57px;
  border-radius: 8px !important;
}

.ql-container.ql-snow {
  border-radius: 8px !important;
  border: 1px solid #ccc !important;
}

.ql-editor {
  height: 250px;
  border-radius: 8px !important;
  background: rgba(255, 255, 255, 0.5);
  p {
    font-family: "Montserrat", serif !important;
  }
}
a {
  text-decoration: none !important;
}

.profileAbout {
  &Three {
    .MuiCircularProgress-root {
      margin-left: 12px;
      width: 20px !important;
      height: 20px !important;
      svg {
        margin-right: 0px !important;
      }
    }
  }
}
.spinner-border {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.react-datepicker__current-month {
  color: white;
}
.react-datepicker__day-name {
  color: white;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  display: none;
}
.carousel .carousel-status {
  display: none;
}
.carousel .control-dots {
  bottom: -12px !important;
}
#react-select-company-listbox {
  left: 0px;
}
.linkText {
  svg {
    path {
      // fill: blue;
      stroke: blue;
    }
    transform: rotate(180deg);
  }
}
.tickSquare {
  path {
    stroke: green;
  }
}
.m-auto {
  margin: auto;
}
.delete-icon {
  border: 1px solid #cd1b3b;
  border-radius: 13px;
  padding: 14px;
  position: absolute;
  bottom: 20px;
  left: 24px;
  cursor: pointer;
}
.like-active {
  svg {
    path {
      stroke: green !important;
      fill: #cae3db !important;
    }
  }
}
.returnButton {
  border: none;
  background: #cde4dd;
  position: absolute;
  padding: 8px 10px;
  border-radius: 8px;
  svg {
    width: 16px;
    height: 16px;
    path {
      stroke: green;
    }
  }
}
.ymaps-2-1-79-map {
  width: 100% !important;
}
.react-datepicker-popper {
  z-index: 99;
}
.css-qbdosj-Input {
  padding-bottom: 0 !important;
  margin: 0 2px !important;
}
.iconPath {
  svg {
    fill: white !important;
    stroke: white !important;
  }
}
.EmojiPickerReact {
  z-index: 999;
  position: absolute !important;
  top: 40px;
  right: 10px;
  width: 300px !important;
  height: 400px !important;
}
.border_24 {
  border-radius: 24px;
}
.custom-react-select__control {
  cursor: pointer !important;
  box-shadow: none !important;
  width: 100% !important;
  min-height: 52px !important;
  height: auto !important;
  border-radius: 8px !important;
  border: 1px solid #b3b3b3 !important;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
}
.custom-react-select__menu {
  z-index: 9999 !important;
}
.custom-react-select__value-container {
  align-items: center;
  display: grid !important;
  height: auto !important;
  flex: 1 !important;
  flex-wrap: wrap !important;
  padding: 0px 8px !important;
  position: relative !important;
  overflow: hidden !important;
}
.custom-react-select__value-container--has-value {
  align-items: center;
  display: flex !important;
  flex: 1;
  /* flex-wrap: wrap; */
  padding: 0px 8px !important;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}
.custom-react-select__indicators {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
}
.emojiField {
  font-family: "Noto Color Emoji", "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif;
}
.overflow-visible {
  overflow: visible;
}
