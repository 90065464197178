.rateModalBody {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .MuiBox-root {
    display: block;
    width: 300px;
    margin-left: 0px;
    color: #a2a2a2;
    font-size: 16px;
    font-weight: 500;
  }
  .MuiRating-root {
    color: #673778;
    svg {
      width: 40px;
      height: 40px;
    }
  }
}
