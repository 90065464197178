.dataMain {
  margin-top: 24px;
  width: 574px;

  img {
    width: 574px;
    height: 296px;
  }
  &Bottom {
    position: relative;
    border: 1px solid #b3b3b3;
    border-radius: 24px;
    &Text {
      padding: 16px;
      h2 {
        font-size: 25px;
      }
      p {
        margin-bottom: 0px;
      }
    }
    .BtnSvg {
      background: none;
      border: none;
      position: absolute;
      top: -5px;
      right: -25px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.partnersInnerButton {
  button {
    border: none;
    background: none;
    margin-right: 5px;
    transition: 0.3s;
    font-weight: 500;
    color: green;
  }

  &:hover {
    button {
      color: green;
    }
  }
  svg {
    transform: rotate(180deg);
    path {
      stroke: green;
    }
  }
}
