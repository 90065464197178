.libraryCard {
  background: #ffffff;
  border: 1px solid #b3b3b34d;
  box-shadow: 0px 0px 10px 0px #6f6f6f1f;
  border-radius: 16px;
  padding: 16px;
  // height: 100%;
  margin: 0 10px;
  &Icon {
    svg {
      width: 32px;
      height: 32px;
      color: white;
    }
  }
  &Input {
    border: none;
    margin-left: 12px;
    outline: none;
  }
  cursor: pointer;
  &:first-child {
    margin-left: 0px;
  }
  transition: 0.3s ease;

  img {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 8px;
  }
  h4 {
    font-weight: 500;
    font-size: 16px;
    margin-left: 14px;
    color: #313538;
    margin-bottom: 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }

  // svg {
  //   width: 24px;
  //   height: 24px;
  //   path {
  //     stroke: white;
  //   }
  // }
}
