.messageMain {
    display: flex;
    padding: 32px 40px;
    // background: #fbf7e3;
    box-shadow: inset 0 0 10px rgb(111 111 111 / 13%);
    position: relative;
    &Left {
        align-items: center;
        &Two {
            margin-left: 24px;
            h4 {
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0.0016em;
                color: #313538;
                margin-bottom: 8px;
            }
            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #464a4d;
                margin-bottom: 0px;
                a {
                    text-decoration: none;
                }
            }
        }
    }
    &Right {
        margin-left: 20px;
    }
}
@media only screen and (max-width: 778px) {
    .messageMain {
        padding: 24px 20px;
        border-radius: 24px;
        margin: 20px;
        display: block !important;
        &Right {
            margin-top: 24px;
            display: flex;
            justify-content: center;
        }
        &Left {
            align-items: flex-start !important;
            &Two {
                p {
                    a {
                        margin: 0px !important;
                    }
                }
            }
        }
    }
}
