.notFoundMain {
  text-align: center;
  margin-top: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &Inside {
    width: 200px;
    margin: auto;
    position: relative;
  }
  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #313538;
    margin-bottom: 0px;
  }
  img {
    max-height: 230px;
    max-width: 264px;
    min-width: 205px;
    min-height: 98px;
    object-fit: cover;
  }
  .buttonMain {
    display: inline !important;
  }
}
