.userCard {
  display: flex;
  align-items: center;
  &Left {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 12px;
    img {
      border-radius: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &Right {
    p {
      margin-bottom: 0px;
      color: #313538;
      font-size: 16px;
      font-weight: 500;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
    }
    span {
      color: #4c5359 !important;
      font-size: 14px !important;
      margin: 0px !important;
      &::before {
        display: none !important;
      }
    }
  }
}
