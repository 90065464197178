.form-wrapper {
  max-width: 603px;
  width: 100%;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 0px 20px rgba(111, 111, 111, 0.15);
  border-radius: 24px;
  .css-z3iohr-Control {
    height: 52px !important;
  }
  select {
    background-repeat: no-repeat !important;
    background-position: right 15px top 17px, 0 0 !important;
    background-size: 0.65em auto, 100% !important;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .input-position {
    position: relative;
    svg {
      position: absolute;
      right: 15px;
      top: 17px;
      width: 18px;
      height: 18px;
      pointer-events: none;
    }
  }

  .input-design {
    width: 100%;
    padding: 16px;
    border-radius: 6px;
    border: 1px solid #a7a6a6;
    background: white;
    outline: none;
    resize: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #464a4d !important;
  }

  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0016em;
    color: #464a4d;
    margin-bottom: 0px;
  }
}

.formbold-btn {
  margin-top: 40px;
  text-align: center;
  border-radius: 12px;
  padding: 14px 26px;
  border: none;
  background-color: #057953;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
