.reviewCard {
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-shadow: 0px 0px 13px rgba(111, 111, 111, 0.15);
    border-radius: 24px;
    position: relative;
    //   height: 340px;

    &Center {
        padding: 70px 24px 24px 24px;
        &Div {
            display: inline;
        }
        button {
            background: transparent;
            color: #313538;
            font-weight: 500;
            font-size: 16px;
            border: none;
            text-decoration: underline;
            line-height: 24px;
        }
        h4 {
            color: #313538;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;
        }
        p {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            margin-bottom: 0px;

            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #464a4d;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
            }
        }
    }
    &Bottom {
        border-top: 1px solid #e6e6e6;
        position: absolute;
        width: 100%;
        bottom: 0px;

        padding: 20px;
        h4 {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 4px;
        }
        span {
            font-weight: 300;
            font-size: 14px;
            line-height: 21px;
            color: #464a4d;
        }
    }
    &Top {
        position: absolute;
        top: -25px;
        left: 24px;
        img {
            width: 80px;
            height: 80px;
            object-fit: cover;
            border-radius: 50%;
        }
    }
}
