.cardMain {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 0px 20px rgba(111, 111, 111, 0.15);
  border-radius: 24px;
  &Top {
    overflow: hidden;
  }
  &Center {
    overflow: hidden;
  }
  &Comment {
    border-top: 1px solid #e6e6e6;
    padding: 34px 24px 24px 24px;

    &List {
      &Box {
        margin-top: 24px;
        display: flex;
        &Left {
          width: 56px;
          height: 56px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        &Right {
          width: 100%;
          margin-left: 20px;
          &Inner {
            border: 1px solid #e2e2e2;
            border-radius: 24px;
            padding: 16px;
            position: relative;

            .name {
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              letter-spacing: 0.0016em;
              color: #313538;
            }
            p {
              color: #6b6e71;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              letter-spacing: 0.0016em;
              margin-bottom: 0px;
            }
            .time {
              color: #6b6e71;
              font-size: 12px;
              line-height: 24px;
              margin-left: 30px;
            }
            svg {
              cursor: pointer;
            }
            .sendButton {
              position: absolute;
              right: 40px;
              bottom: 14px;
              path {
                stroke: green;
              }
            }
          }
          button {
            border: none;
            background: transparent;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: red;
          }
        }
      }
    }
  }
}
.editComment {
  outline: none;
  border: none;
}
.fileSlider {
  position: relative !important;
  width: 300px !important;
  height: 300px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  span {
    width: 100% !important;
    height: 340px !important;
  }
  img {
    border-radius: 24px;
    object-fit: cover;
  }
  video {
    border-radius: 24px;
    object-fit: cover;
    height: 300px;
    width: 100%;
  }
}
.custom-mention {
  background-color: #cee4e5;
  font-weight: bold;
}
