.menu-nav {
  align-items: center;
  flex-direction: row;
  padding: 0px 48px 17px 48px;
  img {
    border-radius: 50%;
    object-fit: cover;
  }

  &_div {
    padding-left: 18px;
    button {
      border: none;
      background: none;
    }
  }
  &_text {
    &:hover {
      color: #057953;
    }
    display: block;
    font-weight: 400;
    text-decoration: none;
    font-size: 14px;
    color: #313538;
    margin-bottom: 0px;
  }
}
.menu-body-main {
  position: relative;
  padding: 12px 36px;
  margin-top: auto;
}
.menu-body {
  padding: 0;
  li:first-child {
    a {
      span {
        font-weight: 700 !important;
      }
    }
  }
  &_item {
    list-style: none;
    margin-bottom: 8px;
    padding: 12px;

    img {
      width: 30px;
      height: 30px;
      object-fit: cover;
    }
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
    }
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.0016em;
      color: #313538;
      margin-left: 20px;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.menu-footer {
  padding: 0;
  &_item {
    list-style: none;
    img {
      width: 24px;
      height: 24px;
      object-fit: cover;
      border-radius: 4px;
    }
    a {
      text-decoration: none;
    }
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #4c5359;
    }
    p {
      font-style: normal;
      margin-left: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #313538;
      margin-bottom: 0px;
    }
  }
  &_button {
    margin-top: 40px;

    svg {
      width: 22px;
      height: 22px;
      margin-right: 15px;
    }
    &:hover {
      color: red;
    }
    background: none;
    border: none;
    display: block;
    font-weight: 400;
    text-decoration: none;
    font-size: 14px;
    color: #313538;
    margin-bottom: 0px;
  }
}

.menu-nav-border {
  margin-top: 20px;
  border-bottom: 1px solid #4c53592f;
}

.aside {
  width: 288px !important;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .brand {
    padding: 17px 48px 20px 48px;
  }
}

.aside-fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 98;
}

.span-active {
  color: #057953 !important;
  font-weight: 600 !important;
}
.icon-active {
  path {
    stroke: #057953 !important;
  }
}
.li-active {
  background: rgba(5, 121, 83, 0.05);
  border-radius: 8px;
}

@media only screen and (max-width: 1020px) {
  .aside {
    display: none;
  }
}
