.categoriesCard {
  background: #ffffff;
  box-shadow: 0 0 20px rgba(111, 111, 111, 0.15);
  border-radius: 24px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  .MuiBox-root {
    display: block !important;
  }
  &Top {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &Left {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      box-shadow: 0 0 20px rgba(111, 111, 111, 0.15);
      border-radius: 8px;
      height: 50px;
      width: 50px;
      cursor: pointer;
      .doneIcon {
        path {
          stroke: white;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      svg {
        width: 24px;
        height: 24px;
      }
    }
    &Center {
      background: #e9f3f1;
      border-radius: 100%;
      font-weight: 600;
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #057953;
    }
  }
  &Center {
    padding: 0 20px 20px 20px;
    h4 {
      font-weight: 600;
      font-size: 15px;
      line-height: 27px;
      color: #057953;
      margin-bottom: 0px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
    }
    h5 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
    }
    &Text {
      // height: 155px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
      font-size: 16px;
      height: 70px;
    }
    &End {
      span {
        font-weight: 500;
        font-size: 14px;
        color: #989a9c;
      }
      .minute {
        margin-left: 10px;
        &::before {
          content: "";
          width: 4px;
          display: inline-block;
          height: 4px;
          border-radius: 7.5px;
          background-color: #a5a9ac;
          position: relative;
          bottom: 2px;
          right: 8px;
        }
      }
    }
  }
  &BottomTop {
    min-height: 70px;
    padding: 14px 20px;
    border-top: 1px solid #4c535927;
    &Right {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      img {
        height: 100%;
        width: 100%;
        border-radius: 100%;
        object-fit: cover;
      }
    }
    &Left {
      p {
        color: #313538;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 0px;
      }
      span {
        font-weight: 500;
        font-size: 12px;
        color: #989a9c;
      }
    }
    .MuiLinearProgress-root {
      background-color: #e6e6e6;
      border-radius: 50px;
      height: 8px;
    }
    .MuiLinearProgress-bar {
      background-color: #057953;
    }
    .MuiBox-root {
      p {
        font-weight: 600;
        font-size: 15px;
      }
    }
    .MuiRating-root {
      color: #673778;
    }
    button {
      border: none;
      background: none;
      display: block;
      font-size: 14px;
      text-decoration: underline;
    }
  }
  &Bottom {
    border-top: 1px solid #4c535927;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    // grid-template-columns: 1fr 1fr;

    gap: 20px;
  }
}

.card-active {
  border: 1px solid #86499b;
}
