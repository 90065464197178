.phCardTop {
  position: relative;
  h1 {
    margin: 24px 0 !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 28px !important;
    line-height: 42px !important;
    color: #313538;
  }
  .phCardTopUl {
    margin-bottom: 0px;
    .phCardTopUlLi {
      margin-bottom: 18px;

      a {
        display: flex;
        text-decoration: none;
        .svgDiv {
          margin-right: 15px;
        }
      }
      span {
        font-family: "OpenSans";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.0016em;
        color: #313538;
      }
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  img {
    display: block;
    width: 100%;
    height: 380px;
    opacity: 0.8;
    object-fit: cover;
    border-radius: 24px;
  }
  .image {
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    transition: 0.2s ease;
    display: block;
    visibility: visible;
  }
  .map {
    height: 380px;
    width: 100%;
    border-radius: 24px;
    iframe {
      width: 100%;
      height: 380px;
      border-radius: 24px !important;
    }
  }
}

ymaps[class$="map"]:first-child:not(:last-child) {
  display: none;
}

.image.hidden {
  display: none;
}
.innerDiv {
  overflow: hidden;
  border-radius: 24px !important;

  &:hover {
    .image {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.phCard {
  &:first-child {
    .image {
      img {
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
}
.phCard {
  &:nth-child(2) {
    .image {
      img {
        &:nth-child(1) {
          display: none;
        }
      }
    }
  }
}
