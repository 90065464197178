.textModalMain {
    overflow-y: scroll;
    max-height: 600px;
    display: block !important;
    margin-top: 10px;
    &::-webkit-scrollbar {
        width: 4px;
        position: absolute;
        top: 16px;
        background-color: #f5f5f5;
        border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #d8d8d8;
    }
}
