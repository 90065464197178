.container {
  flex: 1;
  justify-content: flex-start;
  align-items: center;
}

.input {
  width: 50px;
  height: 50px;
  margin: 10px;
  text-align: center;
  font-size: 20px;
  color: #313131;
  outline: none;
  border: 1px solid #d9d8d8;
  padding: 0px;
  caret-color: transparent;
  user-select: none;
}
.input:focus {
  background: #d9d8d8;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
