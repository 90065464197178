.wrapper {
  padding-left: 288px;
  .body-wrapper {
    position: relative;
    min-height: 100vh !important;
    background: #fafcfc;
    box-shadow: inset 0px 10px 9px -3px rgb(111 111 111 / 10%);
  }
}

.astronaut-container {
  cursor: pointer;
  position: fixed;
  bottom: 0px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

.astronaut-image {
  width: 200px;
}

.bubble-image {
  position: absolute;
  top: -55px;
  right: 140px;
  width: 150px;
}

.bubble-text {
  position: absolute;
  top: -17px;
  right: 148px;
  font-size: 12px;
  font-weight: bold;
  color: black;
  width: 118px;
  word-wrap: break-word;
  flex-wrap: wrap;
  display: flex;
}
@media only screen and (max-width: 1020px) {
  .wrapper {
    padding-left: 0px;
    .body-wrapper {
      background: #ffff;
      box-shadow: none;
    }
  }
}
