.home-card {
    border-radius: 24px;
    position: relative;
    overflow: hidden;

    &Text {
        position: absolute;
        top: 20px;
        max-width: 180px;
        left: 20px;
        background: #057953;
        padding: 10px 15px;
        border-radius: 16px;

        h1 {
            font-weight: 600;
            margin-bottom: 0px;
            color: white;
            font-size: 18px;
        }
    }
    img {
        border-radius: 24px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
