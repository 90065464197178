.img {
  height: 100%;
  width: 100%;
  object-fit: cover !important;
  overflow: hidden;
}
.carousel-modal {
  .modal-dialog {
    width: 1200px;
    height: 900px;
  }
  .downloadIcon {
    padding: 10px;
    background: #cde4dd;
    border-radius: 8px;
    cursor: pointer;
    svg {
      path {
        stroke: #057953;
      }
      width: 20px;
      height: 20px;
    }
  }
}

.img_transition {
  transition: all 1000ms;
}

.modal_wrap {
  align-items: center;
  height: 90%;
  width: 100%;
  padding: 0.5rem 0;
  .slick-prev {
    transform: translateY(-50%) rotate(180deg);
    left: -10px;
    top: 50%;
    position: absolute;
    transition: 0.3s;
    z-index: 999;

    &:hover {
      path {
        stroke: #057953;
        stroke-width: 4;
      }
    }
  }
  .slick-next {
    display: block;
    right: -10px;
    position: absolute;
    transition: 0.3s;
    top: 50%;
    &:hover {
      path {
        stroke: #057953;
        stroke-width: 4;
      }
    }
  }
  // .slick-prev {
  //   left: -54px !important;
  //   &::before {
  //     font-size: 30px;
  //   }
  // }
  // .slick-next {
  //   right: -44px !important;
  //   &::before {
  //     font-size: 30px;
  //   }
  // }
  img,
  video {
    transition: 0.3s ease-in-out;
    width: 100% !important;
    height: 500px !important;
    object-fit: contain !important;
  }
}

.layout {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.close_icon {
  align-self: flex-end;
}

.icon {
  cursor: pointer;
  stroke: green;
  fill: green;
  font-size: 1.5rem;
}

.left_arrow {
  transform: rotate(180deg);
  stroke: green;
  fill: green;
}

.move_left_current_img {
  transform: translateX(-100%);
  opacity: 0;
}

.hide_img_right {
  transform: translateX(100%);
  opacity: 0;
}

.apply_transition {
  transition: transform 1000ms, opacity 1000ms;
}
.move_img_right {
  transform: translateX(0%);
  opacity: 1;
}

.hide_img_left {
  transform: translateX(-100%);
  opacity: 0;
}

.move_img_left {
  transform: translateX(0%);
  opacity: 1;
}

.modal_img_move_left {
  transform: translateX(0);
  opacity: 1;
}
@media only screen and (max-width: 992px) {
  .modal_wrap {
    padding: 0px !important;
    img,
    video {
      width: 100% !important;
      height: 400px !important;
      object-fit: cover !important;
      border-radius: 0px;
    }
  }
  .carousel-modal {
    .modal-header {
      display: none !important;
    }

    .modal-content {
      background: transparent;
      //   .modal-header {
      //     display: none !important;
      //   }
      .modal-header {
        .btn-close {
          left: auto !important;
          right: 8px !important;
          color: white !important;
        }
      }
      .modal-body {
        margin-top: 0px !important;
      }
    }
  }
}
